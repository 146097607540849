<template>
  <BookingPosition>
    <BookingPositionInboxCard />
  </BookingPosition>
</template>

<script>
import BookingPosition from '@/views/BookingPosition.vue'
import BookingPositionInboxCard from '@/components/BookingPosition/BookingPositionInboxCard.vue'

export default {
  name: 'BookingPositionInboxList',
  components: {
    BookingPosition,
    BookingPositionInboxCard
  }
}
</script>

<style lang="less">
</style>
