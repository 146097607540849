<template>
  <b-card
    align="left"
    class="booking-technician-card side-card"
    :class="{ 'has-positions': areThereTechnicianPositions }"
    header-tag="header"
    no-body
  >
    <template #header>
      <b-row>
        <b-col>
          <h5>Inbox</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="position-wrapper" v-if="!areThereTechnicianPositions && !areThereHardwarePositions">
            <div>
              <strong>¡Vaya!</strong>
            </div>
            Parece que no tienes nada en tu inbox
          </div>
        </b-col>
      </b-row>
    </template>
    <b-card-body v-if="areThereTechnicianPositions" class="position-list">
      <div v-for="position in technicianBookingPositions" :key="position.getId">
        <BookingPositionSearchItem
          :position="position"
          @onClick="goToPosition"
          :notification="(showNotification(position.getId))"
        />
      </div>
    </b-card-body>
    <b-card-body v-if="areThereHardwarePositions" class="position-list">
      <div v-for="position in hardwareBookingPositions" :key="position.getId">
        <HardwareBookingPositionSearchItem
          :position="position"
          @onClick="goToHardwareBookingPosition"
          :notification="(showNotification(position.getId))"
        />
      </div>
    </b-card-body>
  </b-card>
</template>
<script>
import BookingPositionSearchItem from '@/components/BookingPosition/BookingPositionSearchItem'
import HardwareBookingPositionSearchItem from '@/components/HardwareBooking/HardwareBookingPositionSearchItem'

export default {
  name: 'BookingPositionInboxCard',
  components: { HardwareBookingPositionSearchItem, BookingPositionSearchItem },
  data () {
    return {
      technicianBookingPositions: [],
      hardwareBookingPositions: [],
      baseUrl: process.env.VUE_APP_IMAGE_API_URL
    }
  },
  created () {
    this.loadData()
  },
  computed: {
    areThereTechnicianPositions () {
      return this.technicianBookingPositions.length > 0
    },
    areThereHardwarePositions () {
      return this.hardwareBookingPositions.length > 0
    }
  },
  methods: {
    loadData () {
      this.$store.dispatch('getPositionInbox')
        .then((res) => {
          this.technicianBookingPositions = res
        })
      this.$store.dispatch('hardwarePosition/getHardwarePositionInbox')
        .then((res) => {
          this.hardwareBookingPositions = res
        })
    },
    goToPosition (id) {
      this.$router.push({
        name: 'positions-inbox-position',
        params: {
          id: id
        }
      })
    },
    goToHardwareBookingPosition (id) {
      this.$router.push({
        name: 'positions-inbox-hardware-position',
        params: {
          id: id
        }
      })
    },
    showNotification (id) {
      return this.$store.getters.technicianBookingNotifications.some((element) => {
        return element.technicianBookingPosition.id === id
      })
    }
  }
}
</script>
<style lang="less">
  @import 'booking-techician.less';
  .position-contents {
    .business-name {
      cursor: pointer;
    }
  }
  .btn-outline {
    outline: none;
    img {
      outline: none;
    }
  }
  .position-wrapper {
    margin-top: 1.3rem;
    margin-bottom: 6.25rem;
  }
</style>
